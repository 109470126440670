<template>
  <b-card-code title="Ajouter un produit">
    <b-card-text>
      <span>Veuillez entrer les détails du produit</span>
    </b-card-text>
    <div class="text-center">
      <b-spinner
        v-if="load === 'true'"
        large
        variant="primary"
      />
    </div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="danger"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ error }} {{ dismissCountDown }}</p>
      <b-progress
        variant="danger"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      />
    </b-alert>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form autocomplete="on">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="reference"
              label-for="reference"
            >
              <validation-provider
                #default="{ errors }"
                name="reference"
                rules="required"
              >
                <b-form-input
                  id="reference"
                  v-model="product.reference"
                  placeholder="reference"
                  :state="errors.length > 0 ? false : null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="produit"
              label-for="produit"
            >
              <validation-provider
                #default="{ errors }"
                name="produit"
                rules="required"
              >
                <b-form-input
                  id="produit"
                  v-model="product.name"
                  placeholder="Produit"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Prix Achat"
              label-for="purchase_price"
            >
              <validation-provider
                #default="{ errors }"
                name="purchase_price"
                rules="required"
              >
                <b-form-input
                  v-model="product.purchase_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prix achat"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Prix ​​de vente"
              label-for="sales_price"
            >
              <validation-provider
                #default="{ errors }"
                name="sales_price"
                rules="required"
              >
                <b-form-input
                  v-model="product.sales_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prix ​​de vente"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="quantité"
              label-for="quantity"
            >
              <validation-provider
                #default="{ errors }"
                name="quantity"
                rules="required"
              >
                <b-form-input
                  v-model="product.quantity"
                  :state="errors.length > 0 ? false : null"
                  placeholder="quantité"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="quantité minimum"
              label-for="minimum_quantity"
            >
              <validation-provider
                #default="{ errors }"
                name="minimum_quantity"
                rules="required"
              >
                <b-form-input
                  v-model="product.minimum_quantity"
                  :state="errors.length > 0 ? false : null"
                  placeholder="quantité minimum du stock"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Categorie"
              label-for="Category"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
              >
                <v-select
                  v-model="product.category"
                  :options="categories"
                  :clearable="false"
                  :reduce="(category) => category.id"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="4">
            <b-form-group
              label="TVA"
              label-for="TVA"
            >
              <validation-provider
                #default="{ errors }"
                name="vat"
                rules="required"
              >
                <v-select
                  v-model="product.vat"
                  :options="vats"
                  :clearable="false"
                  label="value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="4">
            <b-form-group
              label="Fournisseur"
              label-for="supplier"
            >
              <validation-provider
                #default="{ errors }"
                name="supplier"
                rules="required"
              >
                <v-select
                  v-model="product.supplier"
                  :options="suppliers"
                  :clearable="false"
                  :reduce="(supplier) => supplier.id"
                  label="company_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Logo
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="product.image"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <small
                    class="text-muted"
                  >Entrer le logo de votre entreprise.</small>
                  <b-card-text class="my-50">
                    <b-link id="blog-image-text" />
                  </b-card-text>
                  <div class="d-inline-block">
                    <validation-provider
                      #default="{ errors }"
                      name="logo"
                    >
                      <b-form-file
                        ref="refInputEl"
                        v-model="product.image"
                        placeholder="Choose file"
                        :state="errors.length > 0 ? false : null"
                        @input="inputImageRenderer"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col> -->

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addProduct"
            >
              Enregistrer
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormFile,
  BSpinner,
  BAlert,
  BProgress,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'
import { mapGetters, mapActions } from 'vuex'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormFile,
    BSpinner,
    vSelect,
    BAlert,
    BProgress,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      load: 'false',
      product: {
        name: '',
        reference: '',
        barcode: '',
        purchase_price_ttc: '',
        sales_price_ttc: '',
        quantity: '',
        minimum_quantity: '',
        category: '',
        vat: {id:1, value:"19"},
        purchase_price_vat: null,
        supplier: null,
        image: null,
      },
      required,
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      error: null,
    }
  },
  computed: {
    ...mapGetters('category', ['categories']),
    ...mapGetters('vat', ['vats']),
    ...mapGetters('supplier', ['suppliers']),
  },
  created() {
    this.getCategories()
    this.getVats()
    this.getSuppliers()
    // this.product.reference = Math.floor(1000 + Math.random() * 9000)
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    ...mapActions('vat', ['getVats']),
    ...mapActions('supplier', ['getSuppliers']),
    async addProduct() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.load = 'true'

          const formData = new FormData()
          formData.append('reference', this.product.reference)
          formData.append('name', this.product.name)
          formData.append('purchase_price_ttc', this.product.purchase_price)
          formData.append('sales_price_ttc', this.product.sales_price)
          formData.append('quantity', this.product.quantity)
          formData.append('minimum_quantity', this.product.minimum_quantity)
          formData.append('category', this.product.category)
          formData.append('vat', this.product.vat.id)
          formData.append('vat_value', this.product.vat.value)
          formData.append('supplier', this.product.supplier)
          //formData.append('image', this.product.image)

          try {
            await axiosIns.post('/products/create/', formData)
            this.load = 'false'
            this.$router.push('/products/')
            setTimeout(() => {
              this.showToast(
                'success',
                'top-center',
                'produit créé avec succès',
              )
            }, 1000)
          } catch (error) {
            setTimeout(() => {
              if (error.response.data.name) {
                // eslint-disable-next-line prefer-destructuring
                this.error = error.response.data.name[0]
              } else if (error.response.data.reference) {
                // eslint-disable-next-line prefer-destructuring
                this.error = error.response.data.reference[0]
              } else {
                this.error = error.response.data
              }
              this.showAlert()
              window.scrollTo(0, 0)
              this.showToast('danger', 'top-center', this.error)
            }, 1000)
            this.load = 'false'
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champs obligatoire')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
