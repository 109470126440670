var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajouter un produit"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez entrer les détails du produit")])]),_c('div',{staticClass:"text-center"},[(_vm.load === 'true')?_c('b-spinner',{attrs:{"large":"","variant":"primary"}}):_vm._e()],1),_c('b-alert',{attrs:{"show":_vm.dismissCountDown,"dismissible":"","variant":"danger"},on:{"dismissed":function($event){_vm.dismissCountDown = 0},"dismiss-count-down":_vm.countDownChanged}},[_c('p',[_vm._v(_vm._s(_vm.error)+" "+_vm._s(_vm.dismissCountDown))]),_c('b-progress',{attrs:{"variant":"danger","max":_vm.dismissSecs,"value":_vm.dismissCountDown,"height":"4px"}})],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"autocomplete":"on"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"reference","label-for":"reference"}},[_c('validation-provider',{attrs:{"name":"reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reference","placeholder":"reference","state":errors.length > 0 ? false : null,"autofocus":""},model:{value:(_vm.product.reference),callback:function ($$v) {_vm.$set(_vm.product, "reference", $$v)},expression:"product.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"produit","label-for":"produit"}},[_c('validation-provider',{attrs:{"name":"produit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"produit","placeholder":"Produit","state":errors.length > 0 ? false : null},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Prix Achat","label-for":"purchase_price"}},[_c('validation-provider',{attrs:{"name":"purchase_price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Prix achat","type":"number"},model:{value:(_vm.product.purchase_price),callback:function ($$v) {_vm.$set(_vm.product, "purchase_price", $$v)},expression:"product.purchase_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Prix ​​de vente","label-for":"sales_price"}},[_c('validation-provider',{attrs:{"name":"sales_price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Prix ​​de vente","type":"number"},model:{value:(_vm.product.sales_price),callback:function ($$v) {_vm.$set(_vm.product, "sales_price", $$v)},expression:"product.sales_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"quantité","label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"quantité","type":"number"},model:{value:(_vm.product.quantity),callback:function ($$v) {_vm.$set(_vm.product, "quantity", $$v)},expression:"product.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"quantité minimum","label-for":"minimum_quantity"}},[_c('validation-provider',{attrs:{"name":"minimum_quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"quantité minimum du stock","type":"number"},model:{value:(_vm.product.minimum_quantity),callback:function ($$v) {_vm.$set(_vm.product, "minimum_quantity", $$v)},expression:"product.minimum_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Categorie","label-for":"Category"}},[_c('validation-provider',{attrs:{"name":"Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.categories,"clearable":false,"reduce":function (category) { return category.id; },"label":"name"},model:{value:(_vm.product.category),callback:function ($$v) {_vm.$set(_vm.product, "category", $$v)},expression:"product.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Fournisseur","label-for":"supplier"}},[_c('validation-provider',{attrs:{"name":"supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.suppliers,"clearable":false,"reduce":function (supplier) { return supplier.id; },"label":"company_name"},model:{value:(_vm.product.supplier),callback:function ($$v) {_vm.$set(_vm.product, "supplier", $$v)},expression:"product.supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addProduct.apply(null, arguments)}}},[_vm._v(" Enregistrer ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }